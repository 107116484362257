<template>
  <div class="profileBox">
    <!-- 上部图片 -->
    <div class="navbox">
      <!-- 导航nav -->
      <div class="navbtnbox">
        <div class="contextBox">
          <!-- 公司简介 -->
          <div class="one" @click="changeitem(1)">
            公司简介
            <div class="point" v-show="activeitem == 1"></div>
          </div>
          <div class="one" @click="changeitem(2)">
            组织架构
            <div class="point" v-show="activeitem == 2"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司简介和 组织架构内容 -->
    <div v-show="activeitem == 1" class="jianjie">
      <!-- 公司简介 -->
      <div class="companyJJ">
        <div class="abcompanyJJ">
          <div class="title">公司简介</div>
          <div class="context">
            <!-- https://qn.icgushi.com/NANJIXIONGJIAN%3AJIANJIEB.png -->
            
            <img
              src="https://qn.icgushi.com/NANJIXIONG/JIANB.png"
              alt=""
              class="img"
              data-aos="fade-right"
              :data-aos-delay="100"
              :data-aos-offset="50"
            />
            <div
              class="introduce"
              data-aos="fade-left"
              :data-aos-delay="100"
              :data-aos-offset="50"
            >
              南极熊科技有限公司是美国SMARTEL ELECTRONICS (ASIA) CO., LIMITED
              <br />
              在中国深圳成立的一家创新型电子元器件混合型分销商。我们致力于为原始设备
              <br />
              制造商（OEM），原始品牌生产商（OBM），电子产品合约生产商，EMS
              <br />
              （Electronics Manufacturing Service）提供商以及方案设计商（Design
              House）<br />
              提供全面的电子元器件供应链成本优化解决方案与供应链服务。
            </div>
          </div>
        </div>
      </div>
      <!-- 公司前景 -->
      <div class="prospect">
        <div class="prospectTitle">公司前景</div>
        <div class="prospectImgTBox">
          <div class="contextpro">
            南极熊科技公司拥有专业的营销团队和高效的营销网络，公司的核心团队都是在业界从事经验达十五年以上的专业人员。我们以消费电<br />
            子、手机通讯、平板、车载、工控、医疗等电子行业市场为服务对象，专注于为国内外知名生产厂家提供全面的优质电子元器件产品、产品应<br />
            用支持、方案设计、售后技术及物流等方面的专业服务。业务涉及分销、短缺、成本降低、库存处理的全方位服务。我们的专业团队每天通过<br />
            公司数据库管理着全球几十个国家，上千个贸易伙伴的百万条元器件信息，并与北美，欧洲，亚洲地区的各大工厂、原厂和代理商有着紧密的业务往来。
          </div>
          <div class="proImg">
            <img
              :src="item"
              alt=""
              v-for="item in prospectImgList"
              :key="item"
              class="proimgitem"
            />
          </div>
        </div>
      </div>
      <!-- 组织架构 -->
      <div class="architecture">
        <div class="art">组织架构</div>
      </div>
    </div>
    <!-- 组织架构 -->
    <div v-show="activeitem == 2">
      <div class="architecture">
        <div class="art">组织架构</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 简介和架构切换flag
      activeitem: 1,
      // 公司前景图
      prospectImgList: [
        "https://qn.icgushi.com/NANJIXIONGJIAN%3AQIANJINGA.png",
        "https://qn.icgushi.com/NANJIXIONGJIAN%3AQIANJINGB.png",
        "https://qn.icgushi.com/NANJIXIONGJIAN%3AQIANJINGC.png",
      ],
    };
  },
  methods: {
    changeitem(e) {
      this.activeitem = e;
    },
  },
};
</script>

<style scoped lang='scss'>
.profileBox {
  width: 100%;
  .navbox {
    width: 100%;
    height: 400px;
    
    background-image: url("https://qn.icgushi.com/NANJIXIONG/JIANJIE.png");
    // background-image: url("https://qn.icgushi.com/NANJIXIONG/JIANJIE%3ABANNER.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .navbtnbox {
      width: 100%;
      height: 73px;
      background-image: url("https://qn.icgushi.com/NANJIXIONGJIAN%3AYINDAOA.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .contextBox {
        width: 1320px;
        height: 73px;
        margin: 0 auto;
        display: flex;

        .one {
          position: relative;
          width: 169px;
          height: 73px;
          background: #fff;
          border: 1px solid #979797;
          // background-image: url('https://qn.icgushi.com/NANJIXIONGJIAN%3AYINDAOB.png');
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1b4b62;
          line-height: 70px;
          text-align: center;
          .point {
            position: absolute;
            bottom: 10px;
            left: 76px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #3fadc0;
          }
        }
      }
    }
  }
  // 公司简介
  .jianjie {
    width: 100%;

    .companyJJ {
      width: 1320px;
      height: 530px;
      margin: 0 auto;
      position: relative;
      .abcompanyJJ {
        position: absolute;
        top: -98px;
        // width: 1320px;
        width: 1320px;
        height: 574px;
        background-image: url("https://qn.icgushi.com/NANJIXIONGJIAN%3AJIANJIEA.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // padding: 20px;

        .title {
          width: 1200px;
          height: 98px;
          line-height: 98px;
          padding-left: 54px;
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .context {
          width: 1320px;
          height: 400px;
          padding: 0 50px;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          .img {
            width: 350px;
            height: 350px;
            margin-left: 78px;
            margin-right: 127px;
          }
          .introduce {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 30px;
          }
        }
      }
    }
  }
  // prospect  公司前景
  .prospect {
    width: 1320px;
    height: 558px;
    border: 1px solid #dcdcdc;
    margin: 0 auto;
    .prospectTitle {
      box-sizing: border-box;
      width: 1320px;
      height: 92px;
      padding-left: 37px;
      line-height: 92px;
      border-bottom: 1px solid #d3d3d3;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .contextpro {
      width: 1058px;
      height: 174px;
      margin: 45px auto 0;
      // text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 30px;
    }
    .proImg {
      width: 1320px;
      height: 189px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .proimgitem {
        width: 189px;
        height: 189px;
      }
    }
  }
  // 组织架构
  .architecture {
    width: 1361px;
    height: 1190px;
    margin: 94px auto 130px;
    // background-image: url("https://qn.icgushi.com/NANJIXIONGJIAN%3AZUZHI.png");
    background-image: url("https://qn.icgushi.com/NANJIXIONG/ZUZHIJIAGOU.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .art {
      padding-top: 50px;
      padding-left: 63px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
</style>
